.navbar {
  background-color: white;
  width: 100%;
  height: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navbar-link {
  text-decoration: none;
  color: var(--default-color);
}

.navbar-link:hover {
  color: var(--active-hover-color);
  cursor: pointer;
}

.active {
  color: var(--active-color);
}
