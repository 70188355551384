:root {
  --default-color: #213C50;
  --default-hover-color: #637684;
  --active-color: #BE181B;
  --active-hover-color: #d15d5f;
  --disable-color: grey;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* button styles */
button {
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.6rem 1rem;
}

button:hover {
  cursor: pointer;
}

.bg-default {
  background-color: var(--default-color);
}

.bg-default:hover {
  background-color: var(--default-hover-color);
}

.bg-active {
  background-color: var(--active-color);
}

.bg-active:hover {
  background-color: var(--active-hover-color);
}

.button-disable {
  background-color: var(--disable-color);
}

/* common flex box styles */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.content-start {
  justify-content: flex-start;
}

.content-end {
  justify-content: flex-end;
}

.content-between {
  justify-content: space-between;
}

.content-center {
  justify-content: center;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.w-100 {
  width: 100vw;
}

.h-80 {
  height: 80vh;
}

.mw-400 {
  min-width: 400px;
}

/* font size */

.fs-20 {
  font-size: 20px;
}

/* margin and padding */
.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

@media (max-width: 1400px) {
  .toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
}

